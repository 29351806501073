import React, { Component } from 'react';
import * as mainLogo from './assets/main_logo.jpeg';
import * as mainLogoBlack from './assets/main_logo_black.png';
import * as appImage from './assets/app_image.png';
import * as phoneImage from './assets/phone_image.png';
import * as phoneImageTransparent from './assets/phone_image_transparent.png';
import * as phoneImageProgress from './assets/phone_image_progress.png';
import * as phoneImagePhotos from './assets/phone_image_photos.png';
import './Home.css';
import './assets/normalize.css';
import './assets/skeleton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { faUniversalAccess } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: "home",
      isTop: true,
      openHeader: false
    }

    this.selectActive = this.selectActive.bind(this);
    this.toggleHeader = this.toggleHeader.bind(this);
    this.closeHeader = this.closeHeader.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 10;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    });
  }

  selectActive(name) {
    this.setState({
      active: name,
      openHeader: false
    });
  }

  toggleHeader() {
    this.setState({
      openHeader: !this.state.openHeader,
    });
  }

  closeHeader() {
    this.setState({
      openHeader: false
    });
  }

  render() {
    return (
      <div id="home" className="home">

        <header className={ this.state.isTop ? "home-header" : "home-header header-fill"} style={{position: "fixed"}}>
            <div className="header-logo">
              <a onClick={() => {this.selectActive("home")}} href="#home"><img  alt="PointBTech Logo" src={mainLogo} /></a>
            </div>
            <div className={ this.state.openHeader ? "header-tabs open" : "header-tabs"}>
                <a href="#" onClick={this.closeHeader} className="close">
                  <FontAwesomeIcon icon={faWindowClose} />
                </a>
                <a href="#who" className={(this.state.active === "about" ? "Clicked" : "")} onClick={() => {this.selectActive("about")}}>
                  <div className="tab">
                    About
                  </div>
                </a>
              
                <a href="#how" className={(this.state.active === "how" ? "Clicked" : "")} onClick={() => {this.selectActive("how")}}>
                  <div className="tab">How it Works</div>
                </a>
              
                <a href="#download" className={(this.state.active === "down" ? "Clicked" : "")} onClick={() => {this.selectActive("down")}}>
                  <div className="tab">
                  Get Beta <span className="apple">&#63743;</span>
                  </div>
                </a>
              
            </div>
            <div className="header-bars">
              <FontAwesomeIcon onClick={this.toggleHeader} icon={faBars} />
            </div>
        </header>
        <div className="main-content" onClick={this.closeHeader}>
          <section className="section-value">
            <div className="value-columns">
                <div className="value-content">
                      <h4 className="hero-heading">
                      Become your ID
                      </h4>
                      <h4 className="hero-heading">
                      Represent your car registration
                      </h4>
                </div>
                <div className="app-image">
                  <img alt="PointBTech Logo" src={phoneImageTransparent} />
                </div>
            </div>
          </section>
          <section id="who">
            <div className="who-image">
              <img alt="PointBTech Logo" src={phoneImageTransparent} />
            </div>
            <div className="who-content">
              <h1 className="main-title">WHO WE ARE</h1>
              <div className="section-content">
                <div className="tagline">We believe that you are IT! YOU. Your identity, your presence, is all you should need to go through your day to day life.</div>
                
                Our mission is to empower you to control your own destiny by using digital IDs and digital car registration. With the use of our Facial Recognition powered by Artificial Intelligence you will never again have to worry about finding your license, and other government issued IDs in your wallet, purse or bag.
                <br/><br/>
                Our app integrates with the authorities' systems to allow you to just sit back upon a stop without searching for any necessary documents.
                <br/><br/>
              </div>
            </div>
          </section>
          <section id="features">
            <div className="features-content">
              <div className="one feature">
                <div className="icon">
                <FontAwesomeIcon icon={faClipboardCheck} />
                </div>
                <div className="content">
                  <div className="title">
                  Peace of Mind
                  </div>
                  <div className="description">
                    Never worry about losing your ID again.
                  </div>
                </div>
              </div>
              <div className="two feature">
                <div className="icon">
                <FontAwesomeIcon icon={faUniversalAccess} />
                </div>
                <div className="content">
                  <div className="title">
                      Access Through Identity
                  </div>
                  <div className="description">
                      Receive the access you rightfully deserve base on your identity.
                  </div>
                </div>
              </div>
              <div className="three feature">
                <div className="icon">
                <FontAwesomeIcon icon={faAddressCard} />
                </div>
                <div className="content">
                  <div className="title">
                    Control Your Identity
                  </div>
                  <div className="description">
                    No One Can Pretend To Be You.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="how">
            <div className="how-content">
              <div>
                <h1>HOW IT WORKS</h1>
                <div className="app-image mobile">
                  <img alt="PointBTech Logo" src={phoneImageProgress} />
                </div>
                <br/>
                <span className="five-steps">Just five easy steps</span>
                <ol>
                    <li>
                      Download the app
                    </li>
                    <li>
                      Log in with Facebook or Gmail
                    </li>
                    <li>
                    Take a picture of yourself 
                    </li>
                    <li>
                    Take a picture of your Government or State ID
                    </li>
                    <li>
                    Take a picture of your car registration
                    </li>
                  </ol>
              </div>
              <div className="app-image">
                <img alt="PointBTech Logo" src={phoneImageProgress} />
              </div>
              
            </div>
          </section>
          
          <section id="download"  className="section-download">
            <h1>GET BETA</h1>
            <div>
              To Register for our beta contact us at: <br/><br/>
              <a href="mailto:info@pointbtech.com">info@pointbtech.com</a>
            </div>
          </section>
          <section className="section-copyright">

              <div className="copyright">
                Copyright © {(new Date()).getFullYear()} PointBTech - All Rights Reserved.
              </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Home;
