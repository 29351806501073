import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIgloo } from '@fortawesome/free-solid-svg-icons';

library.add(faIgloo);

class App extends Component {
  render() {
    return (
      <BrowserRouter>      
          <Switch>
              <Route exact path="/" component={Home} />
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
